import React from "react"
import Join from "../components/join"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../images/meditating-dog.svg"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <header>
      <img
        src={Image}
        width={189}
        height={260}
        alt="restful coder logo"
      />
    </header>

    <br />
    <br />

    <section>
      <h2>Restful Coder</h2>

      <p>Making software can be stressful. Sure it's not heart surgery but big mistakes can damage your business and your customers. And if you aren't careful, continued stress and anxiety of tight schedules can lead to burnout.</p>


      <h2>Relieve the stress</h2>

      <p>Restful Coder will teach you ways to lower risk and raise confidence throughout the software development lifecycle. It covers a range of topics for both beginners and experts. While I'll use specific object-oriented and functional languages to demonstrate points, the points are generally applicable to software engineering with any language.</p>


      <h2>Burnout</h2>

      <p>One thing you'll hear about frequently as a software engineer is burnout. It's a stressful job that requires ongoing education. Burnout is hard to quantify, but it's the culmination of ongoing stress that ends in an inability to continue working.</p>
      
      <p>Difficult, long projects with high risk can cause you to push hard for extended periods to complete them. When the timeline or scope increases, you're left with additional distance between you and the finish line after you're already exhausted.</p>


      <h2>A little about me</h2>

      <p>My name is David Raffauf. I've been making web apps since the 1990s, mostly working with tech companies in Silicon Valley, Silicon Forest, and in the Northeast U.S.. I started by making university web sites/apps focused on nature conservation and diversity.</p>
        
      <p>I have experience running agile teams, estimating projects and delivering on schedule, and implementing automated testing and CI/CD. Multiple organizations I've worked with release software updates numerous times per day.</p>
    </section>

    <Join />
  </Layout>
)

export default IndexPage
