import React from "react"

const Join = () => (
  <article>
    <h2>Join the community</h2>

    <p>
      <a target="_blank" rel="noreferrer" href="https://discord.gg/gS5wpyPE">Join the community discord</a> or{" "}
      <a target="_blank" rel="noreferrer" href="https://www.youtube.com/c/RestfulCoder">watch the YouTube channel</a>.{" "}
    </p>

    <p>
      Or, find us on <a target="_blank" rel="noreferrer" href="https://twitter.com/restfulcoder">Twitter</a> and{" "}
      <a target="_blank" rel="noreferrer" href="https://www.instagram.com/restfulcoder/">Instagram</a>.
    </p>
  </article>
);

export default Join;
